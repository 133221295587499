












import Vue from "vue";

export default Vue.extend({
  name: "Donations",
  components: {},
  data: () => ({}),
});
